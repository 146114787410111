"use strict";

var header = document.getElementsByClassName('header')[0];

var menu = function menu() {
  var presentation = document.getElementsByClassName('hero')[0]; // const transitionPhase = presentation.clientHeight - header.clientHeight;

  var oldScroll = window.scrollY;
  window.addEventListener('scroll', function (e) {
    var transitionPhase = presentation.clientHeight - header.clientHeight; // console.log(presentation.clientHeight, header.clientHeight, transitionPhase);

    if (oldScroll > window.scrollY) {
      // Go Up
      if (window.scrollY > header.clientHeight && window.scrollY < transitionPhase) {
        // Transition
        header.classList.add('header--transition');
        header.classList.remove('header--initial');
      } else if (window.scrollY > transitionPhase) {
        // Plus bas que le header*2
        header.classList.add('header--sticky');
        header.classList.remove('header--transition');
        header.classList.remove('header--transitionDown');
      } else if (window.scrollY < header.clientHeight) {
        // Plus haut que le header
        header.classList.remove('header--transition');
        header.classList.remove('header--transitionDown');
        header.classList.remove('header--sticky');
        header.classList.add('header--initial');
      }
    } else {
      // Go Down
      if (window.scrollY > transitionPhase) {
        // Plus bas que le header*2
        header.classList.remove('header--sticky');
        header.classList.add('header--transition');
      } else if (window.scrollY > header.clientHeight && window.scrollY < transitionPhase) {
        // Transition
        header.classList.add('header--transitionDown');
        header.classList.remove('header--sticky');
        header.classList.remove('header--initial');
      } else if (window.scrollY < header.clientHeight) {
        // Plus haut que le header
        header.classList.remove('header--transitionDown');
        header.classList.add('header--initial');
      }
    }

    oldScroll = window.scrollY;
  });
  var navItem = document.getElementsByClassName('nav__item');

  for (var i = 0; i < navItem.length; i++) {
    navItem[i].addEventListener('click', function (e) {
      var anchor = e.target.attributes.href.value.replace('#', '');
      var element = document.getElementById(anchor).offsetTop;

      if (header.classList.contains('header--responsive')) {
        header.classList.remove('header--responsive');
        document.getElementsByTagName('body')[0].classList.remove('body--responsive');
      }

      window.scrollTo({
        left: 0,
        top: element,
        behavior: "smooth"
      });
      e.preventDefault();
    });
  }
};

var burgerIcon = function burgerIcon() {
  var burger = document.querySelector('.burger__button');
  burger.addEventListener('click', function (e) {
    if (header.classList.contains('header--responsive')) {
      header.classList.remove('header--responsive');
      document.getElementsByTagName('body')[0].classList.remove('body--responsive');
    } else {
      header.classList.add('header--responsive');
      document.getElementsByTagName('body')[0].classList.add('body--responsive');
    }

    e.preventDefault();
  });
};

var itemPosition = [];

var _giveSize = function _giveSize(galleryName, galleryFunctional) {
  var sizeWindow = window.innerWidth;
  var item = galleryFunctional.getElementsByClassName(galleryName + '__item');
  var nItem = item.length;
  var margin = 10;
  itemPosition = [];
  var newImageSize = 0;

  if (sizeWindow < 1024) {
    newImageSize = galleryFunctional.getElementsByClassName(galleryName + '__image')[0].clientWidth;
  } else {
    newImageSize = document.getElementsByTagName('body')[0].clientWidth / 2;
  }

  var newContainerWidth = 0;

  for (var i = 0; i < nItem; i++) {
    if (i === nItem - 1) margin = 0;
    itemPosition.push(newContainerWidth);
    newContainerWidth += newImageSize + margin;
    item[i].style.width = newImageSize + 'px';
  }

  galleryFunctional.getElementsByClassName(galleryName + '__listItem')[0].style.width = newContainerWidth + 'px';
  var j = document.getElementsByClassName(galleryName + '__item--active')[0].attributes['id'] !== undefined ? document.getElementsByClassName(galleryName + '__item--active')[0].attributes['id'].value.replace(galleryName + '__item', '') : 0;

  if (sizeWindow < 1024) {
    if (itemPosition[j] > galleryFunctional.getElementsByClassName(galleryName + '__listItem')[0].style.width.replace('px', '') - (document.getElementsByTagName('body')[0].clientWidth - 60)) {
      document.getElementsByClassName(galleryName + '__listItem')[0].style.left = -(galleryFunctional.getElementsByClassName(galleryName + '__listItem')[0].style.width.replace('px', '') - (document.getElementsByTagName('body')[0].clientWidth - 60)) + 'px';
    } else {
      document.getElementsByClassName(galleryName + '__listItem')[0].style.left = -itemPosition[j] + 'px';
    }
  } else {
    document.getElementsByClassName(galleryName + '__listItem')[0].style.left = -itemPosition[j] + 'px';
  }
};

var functionality = function functionality() {
  var galleryName = 'galleryFunctional';
  var functionalityName = 'functionality';
  var functionalityItem = document.getElementsByClassName(functionalityName + '__item');
  var galleryFunctional = document.getElementsByClassName(galleryName)[0];
  var item = galleryFunctional.getElementsByClassName(galleryName + '__item'); // need to load images
  // place images

  _giveSize(galleryName, galleryFunctional);

  window.addEventListener('resize', function () {
    _giveSize(galleryName, galleryFunctional);
  });

  var _loop = function _loop(i) {
    item[i].setAttribute('id', galleryName + '__item' + i);
    functionalityItem[i].setAttribute('id', functionalityName + '__item' + i);
    functionalityItem[i].addEventListener('click', function () {
      if (!this.classList.contains(functionalityName + '__item--active')) {
        document.getElementsByClassName(functionalityName + '__item--active')[0].classList.remove(functionalityName + '__item--active');
        document.getElementsByClassName(galleryName + '__item--active')[0].classList.remove(galleryName + '__item--active');
        this.classList.add(functionalityName + '__item--active');
        document.getElementsByClassName(galleryName + '__item')[i].classList.add(galleryName + '__item--active');

        if (window.innerWidth > 1023 || itemPosition[i] < galleryFunctional.getElementsByClassName(galleryName + '__listItem')[0].style.width.replace('px', '') - (document.getElementsByTagName('body')[0].clientWidth - 60)) {
          gsap.to('.' + galleryName + '__listItem', {
            duration: 1,
            left: -itemPosition[i]
          });
        } else {
          gsap.to('.' + galleryName + '__listItem', {
            duration: 1,
            left: -(galleryFunctional.getElementsByClassName(galleryName + '__listItem')[0].style.width.replace('px', '') - (document.getElementsByTagName('body')[0].clientWidth - 60))
          });
        }

        if (window.innerWidth < 1024) {
          var element = document.getElementById('functionality').offsetTop + document.getElementsByClassName('functionality__galleryFunctional')[0].offsetTop - 160;
          window.scrollTo({
            left: 0,
            top: element,
            behavior: 'smooth'
          });
        } // galleryFunctional.getElementsByClassName(galleryName+'__listItem')[0].style.left = -itemPosition[i]+'px';

      }
    });
  };

  for (var i = 0; i < functionalityItem.length; i++) {
    _loop(i);
  }
};

var references = function references() {
  var slider = tns({
    "container": '.galleryReference__listItem',
    "items": 1,
    "center": true,
    "loop": true,
    "swipeAngle": false,
    "speed": 400,
    "mouseDrag": true,
    "gutter": 20,
    "responsive": {
      "1024": {
        "items": 2
      }
    }
  });
};

var video = function video() {
  var playButton = document.getElementsByClassName('play__button')[0];
  var popup = document.getElementsByClassName('popup')[0];
  var close = document.getElementsByClassName('popup__controls')[0];
  var popupStage = document.getElementsByClassName('popup__stage')[0];
  playButton.addEventListener('click', function () {
    popup.classList.add('popup--active');
    popup.style.zIndex = '1000';
  });
  close.addEventListener('click', function () {
    popup.classList.remove('popup--active');
    setTimeout(function () {
      popup.style.zIndex = '-1';
    }, 300);
  });
};

document.addEventListener('DOMContentLoaded', function () {
  menu();
  burgerIcon();
  functionality();
  references();
  video();
});